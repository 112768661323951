import React, { useState, useEffect, useCallback } from 'react';
import uuid from 'react-uuid';
import AlertService from '../../Services/AlertService';
import ApiService from '../../Services/ApiService';
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../Store/Actions/spinner';
import { useDispatch } from 'react-redux';
import ActionButton from '../../Components/Buttons/ActionButton';
import { Card, Modal } from "react-bootstrap"
import signInDecorSvg from "./../../assets/images/decors/sign-in-decor.svg"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MainService from '../../Services/MainService';
import ReactSelectOption from '../../Components/SelectOption/ReactSelectOption';
import { fielsLengths } from '../../Constants/MainKeys';
import { ImEye } from "react-icons/im";
import { ImEyeBlocked } from "react-icons/im";


const buttonSpinnerId = uuid();
export default function CreateAccountForm() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referal = queryParams.get('referal');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isAgree, setIsAgree] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const [values, setValues] = useState({
    legalBusinessName: "",
    firstName: "",
    lastName: "",
    countryId: null,
    email: "",
    password: "",
  })

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
  const [isMismatchPasswords, setIsMismatchPasswords] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);

  useEffect(() => {
    referal && checkInvitation(referal);
  }, [referal])

  useEffect(() => {
    getCountries();

    return () => {
      setCountries([])
    }
  }, [])

  useEffect(() => {
    if (values.password.trim().length) {
      checkFieldsValidation(values.password, "password")
    }
  }, [values])

  const checkInvitation = (referal) => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.checkInvitation(referal).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setValues((values) => ({
          ...values,
          email: data.email,
          legalBusinessName: data.legalBusinessName,
          agentId: data.agentId,
        }))
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractPageSpinner(spinnerId)
    })
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      changeIsinvalidFieldName(isValid, setIsInvalidPassword);
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const getCountries = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractPageSpinner(spinnerId)
    })
  }

  const onCheckBoxChange = (event, cb) => {
    cb(event.target.checked)
  }

  const onChange = (event, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value
    }));
    if (event.target.name === "password" || event.target.name === "confirmPassword") {
      setIsMismatchPasswords(false);
    }
    setIsInvalidSubmit(false);
  }

  const checkPhoneNumber = () => {
    const { phoneNumber } = values;
    // const phoneNumberRegex = /^(\+\d{1,3})?\d{9,15}$/;
    const phoneNumberRegex = /^\+?\d{1,3}[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

    if (!phoneNumber) {
      setIsInvalidSubmit(true);
      return false;
    }

    if (!phoneNumberRegex.test(phoneNumber)) {
      setIsInvalidPhoneNumber(true);
      return false;
    }
    setIsInvalidPhoneNumber(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      // isInvalidPhoneNumber ||
      !values.firstName.trim().length ||
      !values.lastName.trim().length ||
      !values.email.trim().length ||
      // !values.phoneNumber ||
      !values.password.trim().length ||
      !values.legalBusinessName.trim().length ||
      !values.countryId
    ) {
      setIsInvalidSubmit(true);
      return false;
    }
    const valuesCopy = { ...values };
    valuesCopy.password = window.btoa(valuesCopy.password);
    valuesCopy.confirmPassword = window.btoa(valuesCopy.password);
    setButtonSpinner(buttonSpinnerId);
    ApiService.createAccount(referal, valuesCopy).then(response => {
      setIsSubmited(true);
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId)
    })
  }

  const onSelectOptionChange = (item, fieldName) => {
    if (!item) { return false; }
    setValues(values => ({
      ...values,
      [fieldName]: item.value
    }))
    setIsInvalidSubmit(false);
  }

  const closeModal = () => {
    navigate("/")
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  return <section className="cx--cover-container">


    <Modal
      show={isSubmited}
      variant="primary"
      size="lg"
      className='create-prganization-modal'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Body style={{ borderRadius: "10px" }}>
        <div className='d-flex flex-column gap-3'>
          <div className='d-flex justify-content-center'>
            <img src='https://cdn-icons-png.freepik.com/512/7518/7518748.png' width={80} />
          </div>
          <h4 className='text-center'>Congratulations! </h4>
          <p className='text-center'>
            Your registration has been successful. We've sent a verification link to your email. Please check your inbox for verification.
          </p>
          <div className='d-flex justify-content-center'>
            <button
              className={`cx--btn cx--btn-primary px-5`}
              onClick={() => navigate(`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`)}
            >
              Sign In
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <img className="cx--cover-decor" src={signInDecorSvg} alt="signin-decor"></img>
    <div className="container">
      <div className="cx--cover-body-wrapper">
        <div className="cx--cover-form">
          <div className="row">
            <div className='col-12 mb-3 text-center'>
              <h1>
                We look forward to working with you!
              </h1>
            </div>
          </div>
          <form className='mt-3' onSubmit={onSubmit}>

            <div className='row'>
              <div className='col-lg-6 d-block d-lg-none'>
                <p className="cx--card-body cx--text-mute px-3 form-info-text">
                  Create an account with Cryllex to begin the onboarding process. A sales representative
                  will contact you shortly at the email address provided.
                  <br />
                  <br />
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                  <br />
                  <br />
                  It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </div>
              <div className='col-lg-6'>
                <div className="cx--form-control">
                  <input
                    type="text"
                    name="firstName"
                    placeholder='Ex. John'
                    value={values.firstName}
                    className={` ${isInvalidSubmit && !values.firstName.trim().length ? "cx--danger-border" : ""}`}
                    onChange={event => onChange(event, "firstName", fielsLengths.length_120)}
                  />
                  <span>First Name*</span>
                </div>
                <div className="cx--form-control">
                  <input
                    type="text"
                    name="lastName"
                    placeholder='Ex. Smith'
                    value={values.lastName}
                    className={` ${isInvalidSubmit && !values.lastName.trim().length ? "cx--danger-border" : ""}`}
                    onChange={event => onChange(event, "lastName", fielsLengths.length_120)}
                  />
                  <span>Last Name*</span>
                </div>
                <div className="cx--form-control">
                  <input
                    type="email"
                    name="email"
                    placeholder='Ex. example@gmail.com'
                    autoComplete='new-password'
                    readOnly={referal ? true : false}
                    value={values.email}
                    className={`text-initial ${isInvalidSubmit && !values.email.trim().length ? "cx--danger-border" : ""}`}
                    onChange={event => onChange(event, "email", fielsLengths.length_120)}
                  />
                  <span className='text-initial'>E-mail*</span>
                </div>
                <div className="cx--form-control">
                  <input
                    type="text"
                    name="legalBusinessName"
                    placeholder='Ex. Horizon Industries'
                    readOnly={referal ? true : false}
                    value={values.legalBusinessName}
                    className={` ${isInvalidSubmit && !values.legalBusinessName.trim().length ? "cx--danger-border" : ""}`}
                    onChange={event => onChange(event, "legalBusinessName", fielsLengths.length_120)}
                  />
                  <span>Company Name*</span>
                </div>

                <div className="cx--form-control">
                  <ReactSelectOption
                    value={values.countryId}
                    isInvalidSubmit={isInvalidSubmit}
                    selectedValue={(() => {
                      const selectedValue = { ...countries.find(data => data.id === values.countryId) };
                      if (Object.keys(selectedValue).length) {
                        selectedValue.label = selectedValue.name;
                        selectedValue.value = selectedValue.id;
                        return selectedValue;
                      } else {
                        return { label: "Country*", value: null }
                      }
                    })()}
                    className="custom-select "
                    items={countries.map(data => ({ label: data.name, value: data.id }))}
                    onChange={(item) => onSelectOptionChange(item, "countryId")}
                  />
                </div>
                <div className="cx--form-control">
                  <input
                    type={isShowPassword ? "text" : "password"}
                    name="password"
                    placeholder='Password*'
                    autoComplete='new-password'
                    value={values.password}
                    className={`text-initial ${(isInvalidSubmit && !values.password.trim().length) || isInvalidPassword ? "cx--danger-border" : ""}`}
                    onChange={event => onChange(event, "password", fielsLengths.length_120)}
                  />
                  <span>Password*</span>
                  {
                    isShowPassword ?
                      <ImEye className='eye' color='#5D6164' size={22} onClick={() => setIsShowPassword(!isShowPassword)} />
                      :
                      <ImEyeBlocked className='eye' color='#5D6164' size={22} onClick={() => setIsShowPassword(!isShowPassword)} />
                  }
                </div>
                {
                  isInvalidPassword ?
                    <small className="text-danger mb-3 d-block" style={{ fontSize: "14px" }}>Password must contain at least one lowercase letter, one uppercase letter, one number, and at least one special character. Min 6, max 100 symbols.</small>
                    : null
                }

                <div className="d-flex align-items-baseline">
                  <input
                    id='isAgree'
                    type="checkbox"
                    name="isAgree"
                    className='mx-2 d-block'
                    checked={isAgree}
                    onChange={event => onCheckBoxChange(event, setIsAgree)}
                  />
                  <label htmlFor='isAgree' className='mb-1 cursor-pointer'>
                    I agree to the&nbsp;
                    <Link to="#">Terms of Service</Link>
                    &nbsp;and&nbsp;
                    <Link to="/privacy-policy" target='_blank'>Privacy Policy.</Link>
                  </label>
                </div>

                <ActionButton
                  name="Submit"
                  type="submit"
                  disabled={!isAgree || isInvalidPhoneNumber || isMismatchPasswords}
                  className={`cx--btn cx--btn-primary mt-3 px-5 w-100 ${!isAgree ? "disabled" : ""}`}
                  spinnerId={buttonSpinnerId}
                />

                <p className="cx--cover-sub-title mt-4 text-center">Already have an account?
                  {/* &nbsp;<Link to="/sign-in"> Sign in</Link> */}
                  &nbsp;<a href={`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`}> Sign in</a>
                </p>

              </div>

              <div className='col-lg-6 d-none d-lg-block'>
                <p className="cx--card-body cx--text-mute px-3 form-info-text">
                  Create an account with Cryllex to begin the onboarding process. A sales representative
                  will contact you shortly at the email address provided.
                  <br />
                  <br />
                  Expand your business by integrating seamless cryptocurrency payments with Cryllex. Our platform allows merchants to accept digital currencies effortlessly, convert them into fiat, and access global markets without restrictions.
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
}
