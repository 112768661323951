import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../../Components/Buttons/ActionButton';
import MainService from '../../Services/MainService';
import uuid from 'react-uuid';
import { useDispatch } from 'react-redux';

import signUpSvg from "./../../assets/images/sign-up.svg";
import signUpDecorSvg from "./../../assets/images/decors/sign-up-decor.svg";
import { userRegistration } from '../../Store/Actions/user';
import { fielsLengths } from '../../Constants/MainKeys';

const buttonSpinnerId = uuid();
export default function SignUp() {

  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [mismatchPasswords, setMismatchPasswords] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [isInvalidLastName, setIsInvalidLastName] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    setMismatchPasswords(false);
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      changeIsinvalidFieldName(isValid, setIsInvalidPassword);
    }
    if (fieldName === "confirmPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword);
    }
    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (password.trim().length && confirmPassword.trim().length && (password !== confirmPassword)) {
        changeIsinvalidFieldName(false, setMismatchPasswords);
      } else {
        changeIsinvalidFieldName(true, setMismatchPasswords);
      }
    }
    switch (fieldName) {
      case "firstName":
        changeIsinvalidFieldName(isValid, setIsInvalidFirstName)
        break;
      case "lastName":
        changeIsinvalidFieldName(isValid, setIsInvalidLastName)
        break;
      case "email":
        changeIsinvalidFieldName(isValid, setIsInvalidEmail)
        break;
      default:
        break;
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (
      firstName.trim().length &&
      lastName.trim().length &&
      username.trim().length &&
      password.trim().length &&
      confirmPassword.trim().length &&
      !isInvalidFirstName &&
      !isInvalidLastName &&
      !isInvalidEmail &&
      !isInvalidPassword &&
      !mismatchPasswords &&
      !isInvalidConfirmPassword
    ) {
      if (password !== confirmPassword) {
        setMismatchPasswords(true);
        return;
      } else {
        const form = {
          firstname: firstName,
          lastname: lastName,
          email: username,
          password,
          confirmPassword,
        }
        dispatch(userRegistration(form, buttonSpinnerId))
      }
    }
  }

  return <section className="cx--cover-container">
    <img className="cx--cover-decor" src={signUpDecorSvg} alt="signin-decor"></img>
    <div className="container">
      <div className="cx--cover-body-wrapper">
        <div className="row align-items-center">
          <div className="col-xl-6 align-self-center">
            <div className="cx--cover-form">
              <div className="mb-5 d-block">
                <h1 className="cx--cover-form-title">
                  Create new account.
                </h1>
                {/* <p className="cx--cover-sub-title">Already have an account? <Link to="/sign-in">sign in</Link></p> */}
                <p className="cx--cover-sub-title">Already have an account? <a href={`https://dashboard.cryllex.${MainService.getUrlExtension()}/en/login`}>sign in</a></p>
              </div>
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="cx--form-control">
                      <input
                        type="text"
                        placeholder="First name"
                        autoComplete="firstname"
                        value={firstName}
                        name="firstname"
                        required={true}
                        className={`${(isInvalidSubmit && !firstName.trim().length || isInvalidFirstName) ? "cx--danger-border" : ""}`}
                        onChange={(event) => onChange(event, setFirstName, fielsLengths.length_120)}
                        onBlur={() => checkFieldsValidation(firstName, "firstName")}
                      />
                      <span>First name</span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="cx--form-control">
                      <input
                        type="text"
                        placeholder="Last name"
                        autoComplete="lastname"
                        name="lastname"
                        value={lastName}
                        required={true}
                        className={`${(isInvalidSubmit && !lastName.trim().length || isInvalidLastName) ? "cx--danger-border" : ""}`}
                        onChange={(event) => onChange(event, setLastName, fielsLengths.length_120)}
                        onBlur={() => checkFieldsValidation(lastName, "lastName")}
                      />
                      <span>Last name</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="cx--form-control">
                      <input
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        value={username}
                        name="username"
                        required={true}
                        className={`${(isInvalidSubmit && !username.trim().length || isInvalidLastName) ? "cx--danger-border" : ""}`}
                        onChange={(event) => onChange(event, setUsername, fielsLengths.length_120)}
                        onBlur={() => checkFieldsValidation(username, "email")}
                      />
                      <span>E-mail</span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="cx--form-control">
                      <input
                        type="password"
                        placeholder="Password"
                        autoComplete="password"
                        value={password}
                        name="password"
                        required={true}
                        className={`${(isInvalidSubmit && !password.trim().length || isInvalidPassword) ? "cx--danger-border" : ""}`}
                        onChange={(event) => onChange(event, setPassword, fielsLengths.length_100)}
                        onBlur={() => checkFieldsValidation(password, "password")}
                      />
                      <span>Password</span>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="cx--form-control">
                      <input
                        type="password"
                        placeholder="Confirm password"
                        autoComplete="password"
                        value={confirmPassword}
                        name="confirmPassword"
                        required={true}
                        className={`${(isInvalidSubmit && !confirmPassword.trim().length || isInvalidConfirmPassword) ? "cx--danger-border" : ""}`}
                        onChange={(event) => onChange(event, setConfirmPassword, fielsLengths.length_100)}
                        onBlur={() => checkFieldsValidation(confirmPassword, "confirmPassword")}
                      />
                      <span>Confirm password</span>
                    </div>
                  </div>
                  {
                    mismatchPasswords ?
                      <div className='mb-1'><small className="cx--danger-color">	Password mismatch</small></div>
                      : null
                  }
                  {
                    isInvalidConfirmPassword || isInvalidPassword ?
                      <div className='mb-3'>
                        <small className="cx--danger-color">Password must contain at least one lowercase letter, one uppercase letter, one number, and at least one special character. Min 6, max 100 symbols.</small>
                      </div>
                      : null
                  }
                  <div className="col-12">
                    <ActionButton
                      name="Create account"
                      type="submit"
                      className="cx--btn cx--btn-primary"
                      spinnerId={buttonSpinnerId}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="cx--cover-image">
              <img src={signUpSvg} alt="sign-up" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}
