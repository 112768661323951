import axios from 'axios';
import { REFRESH_TOKEN_KEY, USER_KEY, TOKEN_KEY, OBJECT_KEY, LANGUAGE_KEY, DEFAULT_LANGUAGE_KEY, ERROR_KEY } from '../Constants/MainKeys.js';
import FacebookLogo from "./../assets/icons/social-media/facebook.svg";
import LinkedinLogo from "./../assets/icons/social-media/linkedin.svg";
import TwitterLogo from "./../assets/icons/social-media/twitter.svg";
import YoutubeLogo from "./../assets/icons/social-media/youtube.svg";
import AlertService from './AlertService.js';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_KEY || "",
  withCredentials: true,
});

const identityApi = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_API_URL_KEY || "",
});

api.interceptors.request.use(async config => {
  config.headers = {
    Authorization: localStorage.getItem("token")
      ? `Bearer ${localStorage.getItem("token")}`
      : "",
    language: localStorage.getItem("language") ? localStorage.getItem("language") : "en",
  };
  return config;
});

api.interceptors.response.use(response => {
  if (response.data) return response.data;
}, error => {
  if (error.response) {
    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      const user = localStorage.getItem(USER_KEY);
      const token = localStorage.getItem(TOKEN_KEY);
      if (!refreshToken || !user || !token) {
        logout();
      } else {
        getUserDataByRefreshToken(refreshToken, user);
      }
    } else if (error.response.status === 404) { // Status Code: 404
      return Promise.reject("404 Not Found");
    } else if (error.response.status === 500) { // Status Code: 500
      return Promise.reject("500 Internal Server Error");
    }
    if (typeof error.response.data === OBJECT_KEY) {
      const currentError = { ...error.response.data };
      const errors = currentError.errors?.Error;
      let errorMessages = [];
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          const errorParams = error.params;
          const paramKeys = Object.keys(errorParams);
          paramKeys.forEach((key) => {
            const value = errorParams[key];
            if (typeof value === 'string' && value.trim().length > 0) {
              errorMessages.push(value);
            }
          });
        });
      } else {
        return Promise.reject("Unrecognized error");
      }
      if (errorMessages.length) {
        showError(errorMessages);
        return Promise.reject();
      } else {
        return Promise.reject("Unrecognized error");
      }
    } else {
      return Promise.reject("Unrecognized error");
    }

  } else {
    return Promise.reject("Unrecognized error");
  }
});

const showError = (errorMessages) => {
  // errorMessages.forEach(error => {
  //   AlertService.alert("error", error)
  // });

  const filteredNumbers = errorMessages.filter(item => !isNaN(item)); //error numbers

  const filteredStrings = errorMessages.filter(item => isNaN(item));
  filteredStrings.forEach(error => {
    AlertService.alert("error", error)
  });
}
const logout = () => {
  window.location.href = `/${localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY}`;
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
}

const getUserDataByRefreshToken = (refreshToken, user) => {
  ApiService.getUserDataByRefreshToken(refreshToken).then(response => {
    if (response && response.data && response.data.data && response.data.data.accessToken) {
      const data = { ...response.data.data };
      localStorage.setItem(TOKEN_KEY, data?.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
      localStorage.setItem(USER_KEY, user);
    } else {
      logout();
    }
  }).catch(error => getFail(error));
}

const getFail = (error) => {
  if (error) logout();
}


const fakeFooterData = [
  {
    id: 44,
    uri: "/",
    name: "Contact us",
    pageKeywords: "",
    type: 2,
    children: [
      // {
      //   id: 1,
      //   uri: "#",
      //   name: "US: +1 999 666 8888",
      //   pageKeywords: null,
      //   type: 0,
      //   children: [],
      //   filePath: null,
      // },
      // {
      //   id: 2,
      //   uri: "#",
      //   name: "HK: +852 7777 8888",
      //   pageKeywords: "f",
      //   type: 0,
      //   children: [],
      //   filePath: null,
      // },
      {
        id: 3,
        uri: "#",
        name: "Email: info@cryllex.com",
        pageKeywords: null,
        type: 0,
        children: [],
        filePath: null,
      }
    ]
  },
  {
    id: 45,
    uri: "/",
    name: "Community",
    pageKeywords: "",
    type: 2,
    children: [
      {
        id: 4,
        uri: "https://www.facebook.com",
        name: null,
        pageKeywords: null,
        type: 0,
        children: [],
        filePath: FacebookLogo,
      },
      {
        id: 5,
        uri: "https://www.youtube.com",
        name: null,
        pageKeywords: "f",
        type: 0,
        children: [],
        filePath: YoutubeLogo,
      },
      {
        id: 6,
        uri: "https://www.linkedin.com",
        name: null,
        pageKeywords: null,
        type: 0,
        children: [],
        filePath: LinkedinLogo,
      },
      {
        id: 7,
        uri: "https://twitter.com",
        name: null,
        pageKeywords: null,
        type: 0,
        children: [],
        filePath: TwitterLogo,
      }
    ]
  },
]

const unAuthHeader = [
  {
    id: 1,
    uri: "#",
    name: "services"
  },
  {
    id: 2,
    uri: "#",
    name: "supported coines"
  },
  {
    id: 3,
    uri: "#",
    name: "cryllex coins"
  },
  {
    id: 4,
    uri: "#",
    name: "blog"
  },
  {
    id: 5,
    uri: "#",
    name: "use cryllex"
  },
  {
    id: 6,
    uri: "/about-us",
    name: "about"
  },
  {
    id: 7,
    uri: "/contact-us",
    name: "contact us"
  },
  {
    id: 8,
    uri: "/sign-in",
    name: "sign-in"
  },
]

const authHeader = [
  {
    id: 1,
    uri: "/business",
    name: "dashboard"
  },
  {
    id: 2,
    uri: "#",
    name: "services"
  },
  {
    id: 3,
    uri: "#",
    name: "supported coines"
  },
  {
    id: 4,
    uri: "#",
    name: "cryllex coins"
  },
  {
    id: 5,
    uri: "#",
    name: "blog"
  },
  {
    id: 6,
    uri: "#",
    name: "use cryllex"
  },
  {
    id: 7,
    uri: "/about-us",
    name: "about"
  },
  {
    id: 8,
    uri: "/contact-us",
    name: "contact us"
  },
]



class ApiService {

  static login(data) {
    return identityApi.post(`/account/Login`, data)
  };
  // RefreshToken
  static getUserDataByRefreshToken(data) {
    return identityApi.get(`/account/refreshToken?refreshToken=${data}`);
  }

  static currentUser() {
    return api.get(`/user/current`);
  };

  static registration(data) {
    return api.post(`/merchant/create`, data);
  };

  static createAccount(referal, data) {
    // return api.post(`/account/create`, data);
    if (referal) {
      return api.post(`/user/CreateOrganization?referalCode=${referal}`, data);
    } else {
      return api.post(`/user/CreateOrganization`, data);
    }
  };

  static organizationUpdate(formData) {
    return api.put(`/organization/update`, formData);
  };

  static getUrganizationData() {
    return api.get(`/organization/get`);
  };

  static sendError(data) {
    return api.post(`/error/create`, data);
  };

  static getPageByName(name) {
    return api.get(`/page/GetByName/${name}`);
  };

  static getPageContentById(name) {
    return api.get(`/page/GetContent/${name}`);
  };

  static checkInvitation(referal) {
    return api.get(`/agentOrganizationInvitation/checkReferal?referal=${referal}`);
  };

  static getCountries() {
    return api.get(`/country/getBaseModels`);
  };

  static getFooterData() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: fakeFooterData });
      }, 300);
    });
  };

  static getUnAuthHeader() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: unAuthHeader });
      }, 300);
    });
  };

  static getAuthHeader() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: authHeader });
      }, 300);
    });
  };

  static becomeAnAgent(data) {
    return api.post(`/user/CreateAgent`, data);
  };

}

export default ApiService;
