import { takeLatest, call, put } from 'redux-saga/effects';
import ResponseService from '../../Services/ResponseService.js';
import ApiService from "../../Services/ApiService";
import { AUTH_SUCCESS, CURRENT_USER_SUCCESS, USER_LOGIN_REQUEST, USER_REGISTRATION_REQUEST, REGISTRATION_FAIL, AUTH_FAIL } from '../../Constants/ReduxKeys';
import { addButtonSpinner, removeButtonSpinner } from '../Actions/spinner';
import AlertService from '../../Services/AlertService';
import { INVALID_USERNAME_OR_PASSWORD } from '../../Constants/MainKeys';


export default function* watcher() {
  yield takeLatest(USER_LOGIN_REQUEST, userLogin);
  yield takeLatest(USER_REGISTRATION_REQUEST, userRegistration);
};

function* userLogin(action) {
  const { spinnerId } = action.payload;
  try {
    yield put(addButtonSpinner(spinnerId));
    const formData = { ...action.payload.data };
    const token = yield call(ApiService.login, formData);
    if (token && token.data && !token.data.success && (token.data.message || token.data.errors)
    ) {
      throw new Error(token.data.message || "Bad request")
    }
    yield call(ResponseService.responseCheckIdentity, token);
    yield put({
      type: AUTH_SUCCESS,
      payload: {
        token: token?.data,
      }
    });
    const user = yield call(ApiService.currentUser);
    yield put({
      type: CURRENT_USER_SUCCESS,
      payload: user.data,
    });
    yield put(removeButtonSpinner(spinnerId));
  } catch (error) {
    yield put(removeButtonSpinner(spinnerId));
    if (error === INVALID_USERNAME_OR_PASSWORD) {
      yield put({
        type: AUTH_FAIL,
        payload: error,
      });
    } else {
      error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    }
  };
};

function* userRegistration(action) {
  const { spinnerId } = action.payload;
  try {
    yield put(addButtonSpinner(spinnerId));
    const data = { ...action.payload.data };
    data.password = btoa(data.password);
    data.confirmPassword = btoa(data.confirmPassword);

    yield call(ApiService.registration, data);
    yield userLogin({
      type: USER_LOGIN_REQUEST,
      payload: {
        spinnerId,
        data: {
          username: data.email,
          password: btoa(data.password)
        }
      }
    });

    yield put(removeButtonSpinner(spinnerId));
  } catch (error) {
    yield put(removeButtonSpinner(spinnerId));
    AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    yield put({
      type: REGISTRATION_FAIL,
      payload: error
    });
  };
};
